import {
    EVENTBRITE_CATEGORY_MAPPING,
    EVENTBRITE_FORMAT_LOWERCASE_MAPPING_PLURAL,
    EVENTBRITE_FORMAT_MAPPING,
    isCategory,
    isFormat,
} from '@eventbrite/categories';
import { CollectionShape } from '@eventbrite/collection-carousel';
import { parseDate } from '@eventbrite/datetime';
import {
    DATES_LOWERCASE_MAPPING,
    DATES_UPPERCASE_MAPPING,
    EVENTBRITE_CATEGORY_LOWERCASE_MAPPING,
    EventSearch,
    Image,
} from '@eventbrite/discover-utils';
import { HAS_DOCUMENT } from '@eventbrite/eds-utils';
import {
    DestinationEvent,
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { $FixMe } from '@eventbrite/ts-utils';
import { camelCase } from 'lodash';
import every from 'lodash/every';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import mapKeys from 'lodash/mapKeys';
import size from 'lodash/size';
import { formatUrl } from 'url-lib';
import {
    ARTICLE_CARD_TYPE,
    CATEGORY_TYPE,
    COLLECTION_TYPE,
    DEFAULT_BLURB_NO_BUCKETS,
    DEFAULT_BLURB_NO_EVENTS,
    EVENT_CARD_TYPE,
    EVENT_TIME_OPTIONS_TYPE,
    getDefaultBlurb,
    NEARBY_CITY_TYPE,
    SHORT_STATES,
    TRENDING_SEARCH_TYPE,
} from '../constants';
import {
    CITY_BROWSE_SECTION,
    EVENT_AFFILIATES_MAP,
    ONLINE_SEARCH_SECTION,
    SEARCH_SECTION,
} from '../constants/analytics';
import {
    Bucket,
    BucketEntityType,
    BucketsContent,
    TrendingSearch,
    TrendingSearchApiData,
} from '../types';

/*********************** GENERAL UTILS ***********************/

export const createSelector =
    (...selectors: any[]) =>
    (state: any) =>
        [state, ...selectors].reduce((a, b) => b(a));

/*********************** RESPONSE TRANSFORMATION ***********************/

export const createLocationTerm = (
    currentPlace: string,
    currentPlaceParent: string,
) => {
    if (currentPlaceParent) {
        return `${currentPlace}, ${currentPlaceParent}`;
    }

    return currentPlace;
};

/*
@param currentPlace :string: equivalent to currentPlace in the reducer. Where the user currently is. May not be a city.
@oaram currentPlaceParent :string: parsed from autocomplete response in utils/suggestions. Until we have city/region
pulled out in favor of our new place paradigm will need to parse this to properly generate page titles.
EB-95617

@returns :string: San Francisco, CA
*/
export const createLocationForPageTitle = (
    currentPlace: string,
    currentPlaceParent: string,
    placeType?: string,
) => {
    let modifiedPlaceParent = currentPlaceParent;

    if (currentPlaceParent) {
        //Subtitle is not a hierarchy.
        if (currentPlaceParent.indexOf(',') < 0 && placeType !== 'country') {
            if (placeType === 'locality') {
                modifiedPlaceParent = get(
                    SHORT_STATES,
                    currentPlaceParent.toLowerCase(),
                    currentPlaceParent,
                );
            }

            return createLocationTerm(currentPlace, modifiedPlaceParent);
        }

        const splitPlaceParent = currentPlaceParent
            .split(',')
            .map((term: string) => term.trim());

        if (
            (placeType === 'locality' &&
                splitPlaceParent.includes('United States')) ||
            splitPlaceParent.includes('Australia')
        ) {
            // Subtitle: "California, United States"
            modifiedPlaceParent = get(
                SHORT_STATES,
                splitPlaceParent[0].toLowerCase(),
                splitPlaceParent[0],
            );
        } else if (placeType === 'locality') {
            // Subtitle: "Paris, France"
            modifiedPlaceParent = splitPlaceParent[1];
        } else if (placeType === 'region') {
            modifiedPlaceParent = splitPlaceParent[0];
        } else {
            modifiedPlaceParent = '';
        }
    }

    return createLocationTerm(currentPlace, modifiedPlaceParent);
};

const _capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeWords = (string: string) =>
    string.split(' ').map(_capitalizeFirstLetter).join(' ');

export const cleanExtraSpaces = (string: string | GenericLazyString) =>
    string
        .split(' ')
        .reduce((memo: string, currentWord: string) => {
            if (currentWord) {
                return `${memo} ${currentWord}`;
            }

            return memo;
        }, '')
        .trim();

export const getSearchEventHeaderTitle = (
    {
        currentPlace,
        currentPlaceParent,
        placeType,
        isOnline,
    }: {
        currentPlace: string;
        currentPlaceParent: string;
        placeType?: string;
        isOnline?: boolean;
    },
    { eventSearch }: { eventSearch: EventSearch },
    isDocumentTitle = false,
) => {
    let { q, dates, tags, price } = eventSearch;

    const CATEGORY_MAPPING = isDocumentTitle
        ? EVENTBRITE_CATEGORY_MAPPING
        : EVENTBRITE_CATEGORY_LOWERCASE_MAPPING;
    const DATES_MAPPING: {
        [index: string]: GenericLazyString;
    } = isDocumentTitle ? DATES_UPPERCASE_MAPPING : DATES_LOWERCASE_MAPPING;
    const FORMAT_MAPPING = isDocumentTitle
        ? EVENTBRITE_FORMAT_MAPPING
        : EVENTBRITE_FORMAT_LOWERCASE_MAPPING_PLURAL;

    const formatKey = tags?.find(isFormat) ?? '';
    const format = FORMAT_MAPPING[formatKey] || '';
    let query = q || '';

    let priceLabel: GenericLazyString | string = '';
    const tag = tags?.find(isCategory) ?? '';
    const category = CATEGORY_MAPPING[tag] || '';
    const location = createLocationForPageTitle(
        currentPlace,
        currentPlaceParent,
        placeType,
    );

    // we allways add 'current_future' to the date filter, so when a filter is set,
    // dates becomes an array with two options: 'current_future' and the provided filter
    if (dates && isArray(dates) && size(dates)) {
        dates = last(dates);
    }

    const date = DATES_MAPPING[dates as string] || '';

    if (price && price === 'free') {
        priceLabel = gettext('Free');
    }

    // remove keyword 'events' that maybe present at the end of the search query
    // to avoid having the title contain two mentions of keyword 'events'
    // like: Euphoria events events in United States
    if (query.toLocaleLowerCase().endsWith(gettext('events').toString())) {
        query = query.replace(
            new RegExp(gettext('Events').toString() + '$', 'i'),
            '',
        );
    }

    if (isDocumentTitle) {
        //Page title logic prioritizes query over category but does not display both
        const titleFilter = query ? capitalizeWords(query) : category;

        if (every([query, category, date, price], isEmpty)) {
            return cleanExtraSpaces(
                // Translators: "San Francisco, CA All Events | Eventbrite"
                gettext('%(location)s All Events | Eventbrite', {
                    location,
                }),
            );
        }

        return cleanExtraSpaces(
            // Translators: "Free San Francisco, CA Music Festival Events This Weekend | Eventbrite"
            // Translators: "San Francisco, CA Music Festival Events This Weekend | Eventbrite"
            // Translators: "San Francisco, CA Events This Weekend | Eventbrite"
            // Translators: "San Francisco, CA Events Tomorrow | Eventbrite"
            gettext(
                '%(price)s %(location)s %(titleFilter)s %(format)s Events %(date)s | Eventbrite',
                {
                    price: priceLabel,
                    location,
                    titleFilter,
                    format,
                    date,
                },
            ),
        );
    }

    let pageHeader: GenericLazyString | string = '';

    if (isOnline) {
        const titleFormat = format || gettext('events');
        // Translators: "Free Online music events today"
        // Translators: "Online music events today"
        // Translators: "Free Online music classes today"
        // Translators: "Online music business events today"
        // Translators: "Free Online business conferences"
        pageHeader = gettext(
            '%(price)s Online %(query)s %(category)s %(titleFormat)s %(date)s',
            {
                price: priceLabel,
                query,
                category,
                titleFormat,
                date,
            },
        );
    } else {
        if (format && every([query, category, price], isEmpty)) {
            // Translators: "Classes in San Francisco, CA"
            // Translators: "Classes next month in San Francisco, CA"
            pageHeader = gettext('%(format)s %(date)s in %(location)s', {
                format,
                date,
                location,
            });
        } else {
            // Translators: "Free music events today in San Francisco, CA"
            // Translators: "Music events in San Francisco, CA"
            // Translators: "Music events tomorrow in San Francisco, CA"
            // Translators: "Events tomorrow in San Francisco, CA"
            // Translators: "Free new years eve events in San Francisco, CA"
            pageHeader = gettext(
                '%(price)s %(query)s %(category)s events %(date)s in %(location)s',
                {
                    price: priceLabel,
                    query,
                    category,
                    date,
                    location,
                },
            );
        }
    }

    return _capitalizeFirstLetter(cleanExtraSpaces(pageHeader));
};

export const updateDocumentTitle = (newTitle: string) => {
    if (HAS_DOCUMENT) {
        const currentTitle = document.title;

        if (newTitle !== currentTitle) {
            document.title = newTitle;
        }
    }
};

/*
    Set page description based on initial response from the server
    and amount of content present on the page.
 */
export const getPageDescription = (
    currentPlace: string,
    buckets: Bucket[] | string[],
) => {
    /* City has no events and no nearby cities with events */
    let description = DEFAULT_BLURB_NO_BUCKETS;

    /* City has no events, but does have nearby cities with events */
    if (buckets && buckets.length === 1) {
        description = DEFAULT_BLURB_NO_EVENTS;
    }

    /* City has at least one bucket of events and nearby cities */
    if (buckets && buckets.length > 1) {
        description = getDefaultBlurb(currentPlace);
    }

    return description;
};

export const filterUnactionableEvents = (events?: DestinationEvent[]) => {
    if (!events || !events.length) {
        return [];
    }

    return events.filter((event) => {
        const isOnSale = event.event_sales_status?.sales_status === 'on_sale';
        const isRecurrent = !!event.num_children && event.num_children > 1;
        return isOnSale || isRecurrent;
    });
};

/*********************** CARD GETTERS ***********************/

export const _getUrlWithTrackingParams = (
    url: string,
    section = CITY_BROWSE_SECTION,
) =>
    formatUrl(url, {
        aff: EVENT_AFFILIATES_MAP[section],
    });

const _getImageData = (image: Image) => {
    /* Some events don't include an image at all. In which case the expansion is
   set to `null`. So if thats the case we shouldn't event attempt to access */
    let imageUrl;

    let edgeColor;

    let imageUrlOriginal;

    if (image) {
        if (image.url) {
            imageUrl = image.url;
        }

        if (image.edgeColor) {
            edgeColor = image.edgeColor;
        }

        if (image.original && image.original.url) {
            imageUrlOriginal = image.original.url;
        }
    }

    return { imageUrl, edgeColor, imageUrlOriginal };
};

const _articlesCardInfoLookup = (bucketsContent: BucketsContent) =>
    bucketsContent.map(
        ({ id, url, image, title, byline, summary, published, tags }) => {
            const { imageUrl, edgeColor, imageUrlOriginal } =
                _getImageData(image);

            return {
                url,
                id,
                imageUrl,
                imageUrlOriginal,
                edgeColor,
                byline,
                summary,
                published,
                name: title,
                tags,
            };
        },
    );

const _categoryInfoLookup = (bucketsContent: BucketsContent) =>
    bucketsContent.map(({ id, url, localized: { displayName: name } }) => ({
        id,
        name,
        url,
    }));

const _nearbyCityInfoLookup = (bucketsContent: BucketsContent) =>
    bucketsContent.map(({ id, name, url, subhead: distance }) => ({
        id,
        name,
        url,
        distance,
    }));

const _trendingSearchesInfoLookup = (
    bucketsContent: TrendingSearchApiData[],
): TrendingSearch[] =>
    bucketsContent.map(
        ({
            term: searchTerm,
            formatted: formattedSearch,
            search_path: searchPath,
            id,
        }) => ({
            id,
            searchTerm,
            formattedSearch,
            searchPath,
        }),
    );

const _eventsCardInfoLookup = (bucketsContent: $FixMe[]) =>
    bucketsContent.map(transformDestinationEvent);

// TODO: How is this being used?
// It's only being used in city browse
export const CARD_INFO_LOOKUP_MAP: Record<BucketEntityType, Function> = {
    [ARTICLE_CARD_TYPE]: _articlesCardInfoLookup,
    [EVENT_CARD_TYPE]: _eventsCardInfoLookup,
    [CATEGORY_TYPE]: _categoryInfoLookup,
    [NEARBY_CITY_TYPE]: _nearbyCityInfoLookup,
    [TRENDING_SEARCH_TYPE]: _trendingSearchesInfoLookup,
    [COLLECTION_TYPE]: (bucketsContent: CollectionShape[]) => {
        const snakeCaseToCamelCase = (collection: CollectionShape) =>
            mapKeys(collection, (_, key) => camelCase(key));
        return bucketsContent.map(snakeCaseToCamelCase);
    },
    [EVENT_TIME_OPTIONS_TYPE]: (bucketsContent: any) => bucketsContent,
};

/*********************** STATE SELECTORS ***********************/

export const getSearchSection = (isOnlineSearch: any) =>
    isOnlineSearch ? ONLINE_SEARCH_SECTION : SEARCH_SECTION;

//Utilizes the formatDate util found in dateTime but performs some extra logic
export const formatDateRangeStringForDisplay = ({
    startDate = '',
    endDate = '',
}) => {
    const { day: startDay, month: startMonth } = parseDate(startDate);
    const { day: endDay, month: endMonth } = parseDate(endDate);

    //Translators: Feb 3 - Mar 5
    let formattedDateString = gettext(
        '%(startMonth)s %(startDay)s - %(endMonth)s %(endDay)s',
        { startDay, startMonth, endDay, endMonth },
    );

    if (startDate === endDate) {
        //Translators: Feb 3
        formattedDateString = gettext('%(startMonth)s %(startDay)s', {
            startDay,
            startMonth,
        });
    }

    return formattedDateString.toString();
};

/*********************** EVENT TRACKING UTILS ***********************/

export const getEventFromId = (
    events: {
        promoted: FormattedEvent[];
        organic: FormattedEvent[];
    },
    eventIdToFind: string,
    isPromoted: boolean,
) => {
    if (isPromoted) {
        const eventPosition = events.promoted.findIndex(
            (event) => event?.id === eventIdToFind,
        );
        return {
            event: events.promoted[eventPosition],
            absolutePosition: eventPosition + 1,
        };
    }
    const eventPosition = events.organic.findIndex(
        (event) => event?.id === eventIdToFind,
    );
    return {
        event: events.organic[eventPosition],
        absolutePosition: eventPosition + 1,
    };
};
