import { getNearbyCities } from '../../api/nearbyCities';
import { transformIntoThingsToDoShelfData } from '../utils/thingsToDoShelf';

export const FETCH_TTD_SHELF_DATA = 'FETCH_TTD_SHELF_DATA';
export const FETCH_TTD_SHELF_DATA_SUCCESS = 'FETCH_TTD_SHELF_DATA_SUCCESS';

export const thingsToDoShelfDataInProgress = () => ({
    type: FETCH_TTD_SHELF_DATA,
});

export const thingsToDoShelf = (data: any) => ({
    type: FETCH_TTD_SHELF_DATA_SUCCESS,
    payload: data,
});

export const fetchThingsToDoShelfData =
    () => (dispatch: Function, getState: Function) => {
        const {
            location: { placeId, isOnline, slug },
            app,
        } = getState();

        if (app.featureFlags && !app.featureFlags.enableThingsToDoPhase2)
            return;

        dispatch(thingsToDoShelfDataInProgress());

        if (isOnline || !placeId) return;

        return getNearbyCities(placeId)
            .then((response: any) =>
                dispatch(
                    thingsToDoShelf(
                        transformIntoThingsToDoShelfData(
                            slug,
                            response,
                            app.uses_language_subdirectory,
                            app.locale,
                        ),
                    ),
                ),
            )
            .catch(() => {
                // Skip
            });
    };
