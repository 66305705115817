import { CamelCaseLocation } from '@eventbrite/global-nav';
import { createSelector } from 'reselect';
import { getSearchBarProps } from '../../components/ConsumerHeader';
import { DiscoverState } from '../../types';

/**
 * Selector for converting the redux search & location state
 * into searchBar props for the global nav
 */
export const selectSearchBarProps = createSelector(
    [
        (state: DiscoverState) => state.search,
        (state: DiscoverState) => state.location,
    ],
    (search, location) => {
        const eventSearch = search?.eventSearch || {};
        const { slug, placeId, placeType, currentPlace } = location;

        let initialLocation: CamelCaseLocation | undefined;
        if (eventSearch?.bbox) {
            initialLocation = undefined;
        } else if (slug && placeId && placeType && currentPlace) {
            initialLocation = {
                slug,
                placeId,
                placeType,
                latitude: location?.latitude || 0,
                longitude: location?.longitude || 0,
                currentPlace,
                currentPlaceParent: location?.currentPlaceParent || '',
                isOnline: location?.isOnline || false,
            };
        }

        return getSearchBarProps({
            searchQuery: eventSearch?.q,
            location: initialLocation,
            isUsingCurrentLocation: location?.isUsingCurrentLocation,
        });
    },
);
