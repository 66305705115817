/**
 * Transforms the nearby cities data response into Things To Do Shelf Data
 * @param locationSlug current location slug
 * @param data nearby cities response data
 * @returns Things To Do Shelf Data
 */
export const transformIntoThingsToDoShelfData = (
    locationSlug: string,
    data: any,
    usesLanguageSubdirectory: boolean,
    locale: string,
) => {
    const {
        buckets,
        place: { name },
    } = data;

    if (!buckets || !buckets.length || !buckets[0].places) {
        return {
            tags: [],
            header: { placeName: '', url: '' },
        };
    }

    const tags: { placeName: string; linkUrl: string }[] = [];

    buckets[0].places.slice(0, 12).forEach((place: any) => {
        if (place.url) {
            let url = usesLanguageSubdirectory
                ? place.url.replace('/d/', `/${locale}/ttd/`)
                : place.url.replace('/d/', '/ttd/');
            url = url.replace('/events/', '/');
            tags.push({
                placeName: place.name,
                linkUrl: url,
            });
        }
    });

    let headerUrl = `/ttd/${locationSlug}/`;
    headerUrl = usesLanguageSubdirectory ? '/' + locale + headerUrl : headerUrl;
    const header = {
        placeName: name,
        url: headerUrl,
    };

    return {
        tags,
        header,
    };
};
