export const UPDATE_CONTENT = 'updateContent';

/**
 * @param data -- object
 *  {entities, entityContext, pagination}
 */
export const updateContent = (data: object) => ({
    type: UPDATE_CONTENT,
    payload: data,
});
