import { combineReducers } from 'redux';
import {
    FETCH_TTD_SHELF_DATA,
    FETCH_TTD_SHELF_DATA_SUCCESS,
} from '../actions/thingsToDoShelf';

const header = (
    state = { placeName: '', url: '' },
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === FETCH_TTD_SHELF_DATA) {
        return { placeName: '', url: '' };
    }

    if (type === FETCH_TTD_SHELF_DATA_SUCCESS) {
        return payload?.header;
    }

    return state;
};

const tags = (
    state = [],
    { type, payload }: { type: string; payload?: any },
) => {
    if (type === FETCH_TTD_SHELF_DATA) {
        return [];
    }

    if (type === FETCH_TTD_SHELF_DATA_SUCCESS) {
        return payload?.tags;
    }

    return state;
};

export default combineReducers({
    header,
    tags,
});
