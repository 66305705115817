import { combineReducers } from 'redux';

import {
    LOCATION_DENIED,
    SET_WAITING_FOR_LOCATION,
    UPDATE_LOCATION,
    UPDATE_LOCATION_SUGGESTIONS,
} from './actionTypes';

export const currentPlace = (state = '', { type, payload }) => {
    let nextState = state;

    if (
        type === UPDATE_LOCATION &&
        (payload.currentPlace || payload.isOnline)
    ) {
        nextState = payload.currentPlace;
    }

    return nextState;
};

export const currentPlaceParent = (state = '', { type, payload }) => {
    let nextState = state;

    if (
        type === UPDATE_LOCATION &&
        (payload.currentPlaceParent || payload.isOnline)
    ) {
        nextState = payload.currentPlaceParent;
    }

    return nextState;
};

export const city = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION && payload.city) {
        nextState = payload.city;
    }

    return nextState;
};

export const region = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION && payload.region) {
        nextState = payload.region;
    }

    return nextState;
};

export const suggestions = (state = [], { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION_SUGGESTIONS && !payload.suggestions) {
        nextState = payload;
    } else if (type === UPDATE_LOCATION_SUGGESTIONS && payload.suggestions) {
        nextState = payload.suggestions;
    }

    return nextState;
};

export const slug = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION) {
        nextState = payload.slug || '';
    }

    return nextState;
};

export const country = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION) {
        nextState = payload.country || '';
    }

    return nextState;
};

export const countrySlug = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION) {
        nextState = payload.countrySlug || '';
    }

    return nextState;
};

export const placeId = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION) {
        nextState = payload.placeId || '';
    }

    return nextState;
};

const parseFloatOrNull = (coordinate) =>
    coordinate ? parseFloat(coordinate) : null;

export const latitude = (state = 0, { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION && (payload.latitude || payload.isOnline)) {
        nextState = parseFloatOrNull(payload.latitude);
    }

    return nextState;
};

export const longitude = (state = 0, { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION && (payload.longitude || payload.isOnline)) {
        nextState = parseFloatOrNull(payload.longitude);
    }

    return nextState;
};

export const bbox = (state = 0, { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION && (payload.bbox || payload.isOnline)) {
        nextState = payload.bbox;
    }

    return nextState;
};

export const placeType = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION && (payload.placeType || payload.isOnline)) {
        nextState = payload.placeType;
    }

    return nextState;
};

export const isEnglishLanguage = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION && payload.isEnglishLanguage) {
        nextState = payload.isEnglishLanguage;
    }

    return nextState;
};

export const topSuggestions = (state = []) => {
    const nextState = state;

    return nextState;
};

export const cityBlurb = (state = '') => {
    const nextState = state;

    return nextState;
};

export const trendingSearchCities = (state = []) => {
    const nextState = state;

    return nextState;
};

export const isWaitingForLocation = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === SET_WAITING_FOR_LOCATION) {
        nextState = payload;
    }

    if (type === UPDATE_LOCATION || type === LOCATION_DENIED) {
        nextState = false;
    }

    return nextState;
};

export const isUsingCurrentLocation = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION) {
        nextState = !!payload.isUsingCurrentLocation;
    }

    return nextState;
};

export const locationDenied = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === LOCATION_DENIED) {
        nextState = payload;
    }

    return nextState;
};

export const isOnline = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === UPDATE_LOCATION) {
        nextState = !!payload.isOnline;
    }

    return nextState;
};

export default combineReducers({
    currentPlace,
    currentPlaceParent,
    city,
    region,
    suggestions,
    slug,
    country,
    countrySlug,
    placeId,
    latitude,
    longitude,
    bbox,
    placeType,
    isEnglishLanguage,
    topSuggestions,
    cityBlurb,
    trendingSearchCities,
    isWaitingForLocation,
    isUsingCurrentLocation,
    locationDenied,
    isOnline,
});
