import {
    CHARITY_EVENTBRITE_CATEGORY,
    EVENTBRITE_CATEGORY_MAPPING,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import {
    CURRENT_FUTURE,
    DATES_MAPPING,
    EVENTBRITE_CATEGORY_LOWERCASE_MAPPING,
    PRICE_MAPPING,
} from '@eventbrite/discover-utils';
import { gettext } from '@eventbrite/i18n';
import {
    BEST_OF_CITY_EVENTS,
    LIKED_EVENTS,
    ORGANIZERS_TO_FOLLOW,
    PRIMARY_BUCKET,
    SECONDARY_BUCKET,
} from './constants';

export const ALL_TAB = 'all';
export const THIS_WEEKEND_TAB = 'this_weekend';
export const FREE_TAB = 'free';
export const FOR_YOU = 'for_you';
export const MUSIC_TAB = 'music';
export const FOOD_TAB = 'food';
export const CHARITY_TAB = 'charity';
export const SEASONAL_TAB = 'seasonal';
export const TODAY_TAB = 'today';
export const ONLINE_TAB = 'online';

const MUSIC_TAB_NAME = EVENTBRITE_CATEGORY_MAPPING[MUSIC_EVENTBRITE_CATEGORY];
const MUSIC_TAB_NAME_LOWER =
    EVENTBRITE_CATEGORY_LOWERCASE_MAPPING[MUSIC_EVENTBRITE_CATEGORY];

const FOOD_TAB_NAME =
    EVENTBRITE_CATEGORY_MAPPING[FOOD_AND_DRINK_EVENTBRITE_CATEGORY];
const FOOD_TAB_NAME_LOWER =
    EVENTBRITE_CATEGORY_LOWERCASE_MAPPING[FOOD_AND_DRINK_EVENTBRITE_CATEGORY];

const CHARITY_TAB_NAME =
    EVENTBRITE_CATEGORY_MAPPING[CHARITY_EVENTBRITE_CATEGORY];
const CHARITY_TAB_NAME_LOWER =
    EVENTBRITE_CATEGORY_LOWERCASE_MAPPING[CHARITY_EVENTBRITE_CATEGORY];

export const FEED_TABS = [
    ALL_TAB,
    FOR_YOU,
    ONLINE_TAB,
    TODAY_TAB,
    THIS_WEEKEND_TAB,
    `${SEASONAL_TAB}${'_0'}`,
    `${SEASONAL_TAB}${'_1'}`,
    `${SEASONAL_TAB}${'_2'}`,
    `${SEASONAL_TAB}${'_3'}`,
    `${SEASONAL_TAB}${'_4'}`,
    FREE_TAB,
    MUSIC_TAB,
    FOOD_TAB,
    CHARITY_TAB,
];

export const TABS_CONFIG: any = {
    [ALL_TAB]: {
        tabKeyLabel: 'All',
        name: gettext('All'),
        eventSearch: {
            dates: [CURRENT_FUTURE],
            dedup: true,
        },
        feedConfig: {
            [BEST_OF_CITY_EVENTS]: {
                order: 1,
                propsOverwrite: {
                    name: gettext("Editor's picks"),
                    subtitle: {
                        lazyIncludeLocation: (location: string) =>
                            gettext(
                                "Discover %(location)s's best experiences, hand-picked by our experts.",
                                { location },
                            ),
                    },
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                ],
            },
            [PRIMARY_BUCKET]: {
                order: 2,
                propsOverwrite: {
                    name: {
                        lazyIncludeLocation: (location: string) =>
                            gettext('Events in %(location)s', { location }),
                    },
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                            useDefault: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: false,
                            useDefault: true,
                        },
                    },
                ],
            },
            [SECONDARY_BUCKET]: {
                order: 5,
                propsOverwrite: {
                    name: gettext('More events'),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                ],
            },
        },
    },
    [FOR_YOU]: {
        tabKeyLabel: FOR_YOU,
        name: gettext('For you'),
        feedConfig: {
            [LIKED_EVENTS]: {
                order: 2,
                propsOverwrite: {
                    name: gettext('My liked events'),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                        },
                        config: {
                            title: gettext('Like your favorites events'),
                            iconType: 'Heart',
                            iconSize: 'medium',
                            description: gettext(
                                'Get better recomendations and see more events you´ll love',
                            ),
                        },
                    },
                ],
            },
        },
    },
    [ONLINE_TAB]: {
        tabKeyLabel: 'Online',
        name: gettext('Online'),
        eventSearch: {
            online_events_only: true,
            places: [],
        },
        follow: [],
        feedConfig: {
            [PRIMARY_BUCKET]: {
                order: 1,
                propsOverwrite: { name: '' },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                            useDefault: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: false,
                            useDefault: true,
                        },
                    },
                ],
            },
            [SECONDARY_BUCKET]: {
                order: 2,
                propsOverwrite: {
                    name: gettext('More online events'),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                ],
            },
        },
    },
    [THIS_WEEKEND_TAB]: {
        tabKeyLabel: 'ThisWeekend',
        name: DATES_MAPPING[THIS_WEEKEND_TAB],
        eventSearch: {
            dates: [CURRENT_FUTURE, 'this_weekend'],
        },
        follow: [],
        feedConfig: {
            [PRIMARY_BUCKET]: {
                order: 1,
                propsOverwrite: {
                    name: '',
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                            useDefault: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: false,
                            useDefault: true,
                        },
                    },
                ],
            },
            [SECONDARY_BUCKET]: {
                order: 3,
                propsOverwrite: {
                    name: gettext('More events this weekend'),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                ],
            },
        },
    },
    [FREE_TAB]: {
        tabKeyLabel: 'Free',
        name: PRICE_MAPPING[FREE_TAB],
        eventSearch: {
            dates: [CURRENT_FUTURE],
            price: FREE_TAB,
        },
        follow: [],
        feedConfig: {
            [PRIMARY_BUCKET]: {
                order: 1,
                propsOverwrite: {
                    name: '',
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                            useDefault: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: false,
                            useDefault: true,
                        },
                    },
                ],
            },
            [SECONDARY_BUCKET]: {
                order: 2,
                propsOverwrite: {
                    name: gettext('More free events'),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                ],
            },
        },
    },
    [MUSIC_TAB]: {
        tabKeyLabel: 'Music',
        name: MUSIC_TAB_NAME,
        eventSearch: {
            dates: [CURRENT_FUTURE],
            tags: [MUSIC_EVENTBRITE_CATEGORY],
        },
        follow: [MUSIC_EVENTBRITE_CATEGORY],
        feedConfig: {
            [PRIMARY_BUCKET]: {
                order: 1,
                propsOverwrite: {
                    name: '',
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                            useDefault: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: false,
                            useDefault: true,
                        },
                    },
                ],
            },
            [SECONDARY_BUCKET]: {
                order: 3,
                propsOverwrite: {
                    name: gettext('More %(tabName)s events', {
                        tabName: MUSIC_TAB_NAME_LOWER,
                    }),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                ],
            },
        },
    },
    [FOOD_TAB]: {
        tabKeyLabel: 'FoodAndDrink',
        name: FOOD_TAB_NAME,
        eventSearch: {
            dates: [CURRENT_FUTURE],
            tags: [FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
        },
        follow: [FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
        feedConfig: {
            [PRIMARY_BUCKET]: {
                order: 1,
                propsOverwrite: {
                    name: '',
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                            useDefault: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: false,
                            useDefault: true,
                        },
                    },
                ],
            },
            [SECONDARY_BUCKET]: {
                order: 3,
                propsOverwrite: {
                    name: gettext('More %(tabName)s events', {
                        tabName: FOOD_TAB_NAME_LOWER,
                    }),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                ],
            },
        },
    },
    [CHARITY_TAB]: {
        tabKeyLabel: 'Charity',
        name: CHARITY_TAB_NAME,
        eventSearch: {
            dates: [CURRENT_FUTURE],
            tags: [CHARITY_EVENTBRITE_CATEGORY],
        },
        follow: [CHARITY_EVENTBRITE_CATEGORY],
        feedConfig: {
            [PRIMARY_BUCKET]: {
                order: 1,
                propsOverwrite: {
                    name: '',
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                            useDefault: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: false,
                            useDefault: true,
                        },
                    },
                ],
            },
            [SECONDARY_BUCKET]: {
                order: 2,
                orderAfter: [ORGANIZERS_TO_FOLLOW],
                propsOverwrite: {
                    name: gettext('More %(tabName)s events', {
                        tabName: CHARITY_TAB_NAME_LOWER,
                    }),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                ],
            },
        },
    },
    [TODAY_TAB]: {
        tabKeyLabel: 'Today',
        name: DATES_MAPPING[TODAY_TAB],
        eventSearch: {
            dates: [CURRENT_FUTURE, 'today'],
        },
        follow: [],
        feedConfig: {
            [PRIMARY_BUCKET]: {
                order: 1,
                propsOverwrite: {
                    name: '',
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: true,
                            useDefault: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: true,
                            isAuthenticated: false,
                            useDefault: true,
                        },
                    },
                ],
            },
            [SECONDARY_BUCKET]: {
                order: 2,
                propsOverwrite: {
                    name: gettext('More events today'),
                },
                emptyState: [
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: true,
                        },
                    },
                    {
                        validation: {
                            shouldShowEmptyState: false,
                            isAuthenticated: false,
                        },
                    },
                ],
            },
        },
    },
};
