import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as api from './api';
import reducer, {
    currentPlace,
    currentPlaceParent,
    city,
    region,
    suggestions,
    slug,
    country,
    countrySlug,
    placeId,
    latitude,
    longitude,
    bbox,
    placeType,
    isEnglishLanguage,
    topSuggestions,
    cityBlurb,
    trendingSearchCities,
    isWaitingForLocation,
    isUsingCurrentLocation,
    locationDenied,
    isOnline,
} from './reducer';

export default {
    api,
    reducer,
    actionTypes,
    actions,
    constants,
    reducersByKey: {
        currentPlace,
        currentPlaceParent,
        city,
        region,
        suggestions,
        slug,
        country,
        countrySlug,
        placeId,
        latitude,
        longitude,
        bbox,
        placeType,
        isEnglishLanguage,
        topSuggestions,
        cityBlurb,
        trendingSearchCities,
        isWaitingForLocation,
        isUsingCurrentLocation,
        locationDenied,
        isOnline,
    },
};
