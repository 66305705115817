import React from 'react';
import _ from 'lodash';
import { filter, flow, map, take } from 'lodash/fp';
import { gettext } from '@eventbrite/i18n';
import { Icon } from '@eventbrite/eds-icon';
import { CheckChunky } from '@eventbrite/eds-iconography';
import { Clock } from '@eventbrite/eds-iconography';
import { Crosshair } from '@eventbrite/eds-iconography';
import { VideoChunky } from '@eventbrite/eds-iconography';
import {
    getNavigatorLastRecentSearchTerms,
    getNavigatorLastLocation,
} from '@eventbrite/personalization';
import { HAS_WINDOW } from '@eventbrite/feature-detection';

import {
    BROWSING_ONLINE_EVENTS_TEXT,
    HISTORY_SUGGESTIONS_SHOWN,
    SHORT_STATES,
    ONLINE_EVENTS_LOCATION_SLUG,
    SUGGESTION_TYPE_HISTORY,
} from '../constants';

export const getOnlineEventsOption = (onlineEventsContent) => ({
    slug: ONLINE_EVENTS_LOCATION_SLUG,
    currentPlace: BROWSING_ONLINE_EVENTS_TEXT.toString(),
    currentPlaceParent: '',
    latitude: null,
    longitude: null,
    value: ONLINE_EVENTS_LOCATION_SLUG,
    content: onlineEventsContent || gettext('Browse online events'),
    placeType: null,
    iconType: <VideoChunky />,
    isOnline: true,
});

/*
 * @param item -- object -- ${item}: ${content, secondaryContent}
 *      - Object with the values to be shown as a list item
 * @param shouldShowCheck -- bool -- ${shouldShowCheck}: ${true|false}
 *      - Boolean variable to enable or disable the check on the list item
 *      - Default value: false.
 *
 * Function takes in two texts and a boolean variable and returns an html object with
 * a proper formatting and an optional check on the side.
 */
export const addCheckToSuggestion = ({
    item: { content, secondaryContent },
    shouldShowCheck = false,
}) => {
    let currentLocationIcon = null;

    const _secondaryContent = (
        <div className="eds-text-list-item__content-secondary">
            {secondaryContent}
        </div>
    );

    if (shouldShowCheck) {
        currentLocationIcon = (
            <Icon type={<CheckChunky />} size="small" color="vibrant-green" />
        );
    }

    return (
        <div className="eds-align--center-vertical eds-align--space-between">
            <div>
                {content}
                {_secondaryContent}
            </div>
            {currentLocationIcon}
        </div>
    );
};

function getHistorySuggestions({
    inputValue,
    isSearchHistory,
    isLocationHistory,
}) {
    let historySuggestions = [];

    try {
        if (HAS_WINDOW) {
            if (isSearchHistory) {
                historySuggestions = getNavigatorLastRecentSearchTerms();
            } else if (isLocationHistory) {
                const lastLocation = getNavigatorLastLocation();

                if (lastLocation) {
                    historySuggestions = [JSON.stringify(lastLocation)];
                }
            }

            if (inputValue) {
                historySuggestions = historySuggestions.filter((value) =>
                    value.toLowerCase().startsWith(inputValue.toLowerCase()),
                );
            }
        }
    } catch (error) {
        // in production, we silently fail and drop the history feature
        if (process.env.NODE_ENV === 'development') {
            throw error;
        }
    }
    return historySuggestions;
}

export const parseHistorySuggestions = ({
    inputValue,
    place,
    isSearchHistory = false,
    isLocationHistory = false,
} = {}) => {
    const historySuggestions = getHistorySuggestions({
        inputValue,
        isSearchHistory,
        isLocationHistory,
    });

    return flow(
        map.convert({ cap: false })((value, index) => {
            let itemValue = { value, content: value };

            if (isLocationHistory) {
                itemValue = getNavigatorLastLocation();
                itemValue.content = addCheckToSuggestion({
                    item: itemValue,
                    shouldShowCheck:
                        place &&
                        place ===
                            itemValue.content + itemValue.secondaryContent,
                });
                itemValue.secondaryContent = null;
            }

            return {
                ...itemValue,
                iconType: <Clock />,
                header: index === 0 ? gettext('Recent') : null,
                showDivider:
                    historySuggestions.length === index + 1 || index === 4,
                dividerVertSpacing: 2,
                suggestionType: SUGGESTION_TYPE_HISTORY,
            };
        }),
        take(HISTORY_SUGGESTIONS_SHOWN),
    )(historySuggestions);
};

const parseLocationSuggestions = ({
    locationSuggestions,
    isTyping = false,
    locationQuery,
}) => {
    const shouldShowHeader = !isTyping || !locationQuery;

    return flow(
        map.convert({ cap: false })((value, index) => ({
            ...value,
            header: index === 0 && shouldShowHeader ? gettext('Popular') : null,
        })),
    )(locationSuggestions);
};

const parseInitialLocationSuggestions = ({
    currentPlace,
    currentPlaceParent,
    isUseCurrentLocationEnabled,
    suggestions: locationSuggestions,
    usingCurrentLocation,
    isOnline,
}) => {
    let _suggestions = parseLocationSuggestions({ locationSuggestions });
    const historySuggestions = parseHistorySuggestions({
        place: currentPlace + currentPlaceParent,
        isLocationHistory: true,
    });

    let useCurrentLocation = [];

    let browseOnlineEvents = [];

    if (isUseCurrentLocationEnabled) {
        let currentPlaceSecondaryContent = '';

        let shouldShowCheck = false;

        if (usingCurrentLocation) {
            currentPlaceSecondaryContent = currentPlace;
            shouldShowCheck = true;
        }

        const currentLocationContent = addCheckToSuggestion({
            item: {
                content: gettext('Use my current location'),
                secondaryContent: currentPlaceSecondaryContent,
            },
            shouldShowCheck,
        });
        const currentLocationOption = {
            value: '',
            content: currentLocationContent,
            iconType: <Crosshair />,
        };

        useCurrentLocation = [currentLocationOption];
    }

    const shouldShowCheck = isOnline;

    const onlineEventsContent = addCheckToSuggestion({
        item: {
            content: gettext('Browse online events'),
            secondaryContent: '',
        },
        shouldShowCheck,
    });

    const onlineEventsOption = getOnlineEventsOption(onlineEventsContent);

    browseOnlineEvents = [onlineEventsOption];

    _suggestions = [
        ...useCurrentLocation,
        ...browseOnlineEvents,
        ...historySuggestions,
        ..._suggestions,
    ];

    return _suggestions;
};

export const getLocationSuggestions = (
    {
        suggestions,
        topSuggestions,
        currentPlace,
        currentPlaceParent,
        usingCurrentLocation = false,
        isOnline = false,
    },
    isUseCurrentLocationEnabled = false,
) => {
    if (_.isEmpty(suggestions)) {
        return {
            suggestions: parseInitialLocationSuggestions({
                currentPlace,
                currentPlaceParent,
                isUseCurrentLocationEnabled,
                suggestions: topSuggestions,
                usingCurrentLocation,
                isOnline,
            }),
            hasSuggestions: false,
        };
    }

    return {
        suggestions,
        hasSuggestions: true,
    };
};

//Helper to below which returns the
//abbreviated region if it should
const _getAbbreviatedRegion = (region) => {
    let abbreviated;

    if (region) {
        abbreviated = SHORT_STATES[region.toLowerCase()];
    }

    return abbreviated ? abbreviated : region;
};

// Util which parses response from the Location Autocomplete Endpoint
// into a format the LocationAutocomplete and our store understands
export const parseLocationResponse = ({ places }, featureFlags) => {
    let enabledPlaces = ['locality', 'borough'];

    if (featureFlags && featureFlags.isRegionAndCountrySearchEnabled) {
        enabledPlaces = [...enabledPlaces, 'region', 'country'];
    }

    return flow(
        filter(
            ({ place_type: placeType }) =>
                enabledPlaces.indexOf(placeType) > -1,
        ),
        map(
            ({
                id,
                slug,
                name,
                subtitle,
                latitude,
                longitude,
                bbox,
                place_type: placeType,
            }) => ({
                slug,
                placeType,
                currentPlace: name,
                currentPlaceParent: _getAbbreviatedRegion(subtitle),
                latitude: latitude ? parseFloat(latitude) : '',
                longitude: longitude ? parseFloat(longitude) : '',
                bbox,
                value: id,
                content: name,
                secondaryContent: subtitle,
            }),
        ),
    )(places);
};
